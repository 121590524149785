<script>
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Keuntungan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  },
  data() {
    return {
      title: "Tambah Keuntungan",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Keuntungan",
          href: "/master/Keuntungan",
        },
        {
          text: "Tambah Keuntungan",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: ["one", "two", "three"],
      role_selected: "",
      keuntungan: "",
    };
  },
  mounted() { },
  methods: {
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("keuntungan", self.keuntungan);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/keuntungan/store",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master keuntungan segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$emit("refresh-table", "add");
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <b-form class="p-2" @submit.prevent="StoreData">
        <div class="row">
          <div class="col-md-12">
            <b-form-group class="mb-3" label="Keuntungan" label-for="formrow-Keuntungan-input">
              <b-form-input id="formrow-Keuntungan-input" type="text" v-model="keuntungan"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-end">
            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>
